<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical">
        <img :style="{ width: '210px' }" :src="require('@/assets/img/logo.png')" alt="" />
        <sdHeading as="h3">Sign in to Your Account</sdHeading>
        <a-form-item name="username" label="Email Address">
          <a-input type="email" placeholder="Email" v-model:value="formState.email" />
        </a-form-item>
        <a-form-item name="password" initialValue="123456" label="Password">
          <a-input type="password" v-model:value="formState.password" placeholder="Password" />
        </a-form-item>
        <div class="auth-form-action">
          <a-checkbox @change="onChange">Keep me logged in</a-checkbox>
          <router-link class="forgot-pass-link" to="/portal/forgot-password">
            Forgot password?
          </router-link>
        </div>
        <a-form-item>
          <sdButton class="btn-signin" htmlType="submit" type="primary" size="large">
            {{ isLoading ? 'Loading...' : 'Sign In' }}
          </sdButton>
        </a-form-item>
        <p class="form-divider">
          <span>Or</span>
        </p>
        <p class="auth-notice text-center">Don&rsquo;t have an account? <router-link to="/portal/register">Sign up now</router-link></p>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Notification from "@/services/Notification";

import { AuthWrapper } from './style';

const SignIn = {
  name: 'SignIn',
  components: { AuthWrapper },
  setup() {
    const { state, dispatch, commit } = useStore();
    const isLoading = computed(() => state.auth.loading);
    const checked = ref(false);
    const router = useRouter();
    const ini = router.currentRoute.value.path.split("/")[1];
    commit("setInitial", ini);
    const handleSubmit = async () => {
      try {
        const res = await dispatch('login', formState);
        const { status, message = "", data = {} } = res;
        const { onboarding = false } = data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Auth Success" : "Auth Error",
          });
        }
        if (status) {
          if (data.active_plan == 0) {
            router.replace({ path: "pricing" });
          } else {
            router.replace({ path: onboarding ? "onboarding" : "dashboard" });
          }
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error", title: "Error"});
        }
      }
    };
    const onChange = checked => {
      checked.value = checked;
    };

    const formState = reactive({
      email: '',
      password: '',
    });

    return {
      isLoading,
      checked,
      handleSubmit,
      onChange,
      formState,
    };
  },
};

export default SignIn;
</script>
